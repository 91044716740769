define("sc-portal/helpers/ceil", ["exports", "ember-math-helpers/helpers/ceil"], function (_exports, _ceil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _ceil.default;
    }
  });
  Object.defineProperty(_exports, "ceil", {
    enumerable: true,
    get: function get() {
      return _ceil.ceil;
    }
  });
});