define("sc-portal/components/document-download-link", ["exports", "js-common/components/document-download-link"], function (_exports, _documentDownloadLink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _documentDownloadLink.default;
    }
  });
});