define("sc-portal/helpers/doc-type-display-name", ["exports", "js-common/helpers/doc-type-display-name"], function (_exports, _docTypeDisplayName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _docTypeDisplayName.default;
    }
  });
  Object.defineProperty(_exports, "docTypeDisplayName", {
    enumerable: true,
    get: function get() {
      return _docTypeDisplayName.docTypeDisplayName;
    }
  });
});