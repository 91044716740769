define("sc-portal/helpers/avatar-url", ["exports", "ember-get-config", "js-common/utils/get-app-url"], function (_exports, _emberGetConfig, _getAppUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    session: Ember.inject.service(),
    compute: function compute(params, hash) {
      var session = this.session;

      if (params[0]) {
        return (0, _getAppUrl.default)(_emberGetConfig.default, "".concat(params[0], "?token=").concat(session.data.authenticated.token));
      } else {
        return null;
      }
    }
  });

  _exports.default = _default;
});