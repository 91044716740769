define("sc-portal/helpers/cosh", ["exports", "ember-math-helpers/helpers/cosh"], function (_exports, _cosh) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _cosh.default;
    }
  });
  Object.defineProperty(_exports, "cosh", {
    enumerable: true,
    get: function get() {
      return _cosh.cosh;
    }
  });
});