define("sc-portal/pods/auth/otp-login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IcfznvQ4",
    "block": "{\"symbols\":[\"Layout\"],\"statements\":[[5,\"sc-external-layout\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[6,[24,1,[\"leftPanel\"]],[],[[],[]]],[0,\"\\n  \"],[6,[24,1,[\"rightPanel\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"sc-otp-login\",[],[[\"@year\",\"@isProcessing\",\"@model\",\"@goToLogin\",\"@loginOtp\"],[[24,0,[\"year\"]],[24,0,[\"isProcessing\"]],[24,0,[\"model\"]],[29,\"route-action\",[\"goToLogin\"],null],[29,\"route-action\",[\"loginOtp\"],null]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sc-portal/pods/auth/otp-login/template.hbs"
    }
  });

  _exports.default = _default;
});