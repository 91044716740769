define("sc-portal/models/user", ["exports", "js-common/models/user"], function (_exports, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _user.default.extend({});

  _exports.default = _default;
});