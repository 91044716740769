define("sc-portal/pods/components/matter/new-report/last-downloaded/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vD15Qcgz",
    "block": "{\"symbols\":[\"@row\"],\"statements\":[[7,\"div\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n\"],[4,\"if\",[[29,\"lt\",[[29,\"moment-diff\",[[24,1,[\"content\",\"hasBeenViewedOn\"]],[25,[\"now\"]]],[[\"precision\"],[\"days\"]]],8],null]],null,{\"statements\":[[0,\"    \"],[1,[29,\"moment-from-now\",[[24,1,[\"content\",\"hasBeenViewedOn\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[29,\"moment-format\",[[24,1,[\"content\",\"hasBeenViewedOn\"]],\"MM/DD/YYYY\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sc-portal/pods/components/matter/new-report/last-downloaded/template.hbs"
    }
  });

  _exports.default = _default;
});