define("sc-portal/helpers/get-date", ["exports", "js-common/helpers/get-date"], function (_exports, _getDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _getDate.default;
    }
  });
  Object.defineProperty(_exports, "getDate", {
    enumerable: true,
    get: function get() {
      return _getDate.getDate;
    }
  });
});