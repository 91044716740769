define("sc-portal/models/email-log", ["exports", "js-common/models/email-log"], function (_exports, _emailLog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emailLog.default.extend({});

  _exports.default = _default;
});