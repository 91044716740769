define("sc-portal/components/subject-edit-field", ["exports", "js-common/components/subject-edit-field"], function (_exports, _subjectEditField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _subjectEditField.default;
    }
  });
});