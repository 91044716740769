define("sc-portal/pods/matter/route", ["exports", "js-common/helpers/is-orphan-matter-field", "js-common/utils/get-sc-portal-report-tab", "@smith-carson/ui/utils/sc-table/column"], function (_exports, _isOrphanMatterField, _getScPortalReportTab, _column) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentMatter: Ember.inject.service(),
    session: Ember.inject.service(),
    counts: Ember.inject.service(),
    // load matter_id at the earliest possible moment for nested routes to reference
    beforeModel: function beforeModel(transition) {
      this._super(transition);

      var currentUserId = this.get('session.data.authenticated.id');
      var matter = this.currentMatter;
      var parentRoute = transition.params.matter;
      var matterId = parentRoute.matter_id;
      var self = this;

      if (Ember.isEmpty(matterId)) {
        console.debug('Could not load expected matter_id!');
      }

      matter.set('id', matterId);
      return this.store.findRecord('matter', matterId).then(function (m) {
        matter.set('matter', m);
      }, function (e) {
        transition.abort();

        if (!Ember.isEmpty(currentUserId)) {
          self.transitionTo('403');
        }

        return Ember.RSVP.reject(e);
      });
    },
    model: function model(params) {
      var matter = this.currentMatter;
      return Ember.RSVP.hash({
        matter: matter.get('matter'),
        scPortalReportTab: this.store.query('appl-config', {
          name: ['matters.default.sc_portal_report_tab', "matters.".concat(this.currentMatter.id, ".sc_portal_report_tab")]
        }),
        matterFields: this.store.query('matter_field', {
          matter_id: params.matter_id
        }),
        scPortalDashboardType: this.store.query('appl-config', {
          name: ['matters.default.sc_portal_dashboard_type', "matters.".concat(this.currentMatter.id, ".sc_portal_dashboard_type")]
        }),
        totalSubjects: this.counts.query('subject', {
          matter_id: params.matter_id,
          viewable: 1
        })
      });
    },

    /**
     * save the open matter to currentMatter service
     *
     * @param controller
     * @param model
     */
    setupController: function setupController(controller, resolved) {
      var currentMatter = this.currentMatter;
      currentMatter.set('matter', resolved.matter);
      currentMatter.set('matterFields', resolved.matterFields.filterBy('isViewablePortal'));
      currentMatter.set('filterMatterFields', resolved.matterFields.filterBy('isPortalFilter'));
      /* remove some hard coded values from the list
         since these have specific formatting on the page */

      var matterFields = currentMatter.get('matterFields').filter(function (field) {
        return field.get('value') === 'last_name' || field.get('value') === 'first_name' || field.get('isViewablePortalSubjectTable');
      });
      var i = 0;

      var createColumn = function createColumn(field) {
        var enabledFilterOperators = [];
        var filterOperator = null;

        if (field.get('fieldType') === 'enum' && field.get('value') !== 'location') {
          filterOperator = "select_".concat(field.get('value'));
          enabledFilterOperators.push("select_".concat(field.get('value')));
        } else if (field.get('value') === 'location') {
          filterOperator = 'equal';
          enabledFilterOperators.push('csv');
          enabledFilterOperators.push('equal');
        } else if (field.get('fieldType') === 'date') {
          filterOperator = 'between_dates';
          enabledFilterOperators.push('between_dates');
        } else {
          filterOperator = 'contains';
          enabledFilterOperators.push('contains');
        }

        i += 1;
        return _column.default.create({
          displayName: field.get('display'),
          fieldName: Ember.String.camelize(field.get('value')),
          apiName: Ember.String.underscore(field.get('value')),
          order: i,
          width: "".concat(field.get('columnWidth'), "px"),
          cellType: 'subject',
          enabledFilterOperators: enabledFilterOperators,
          filterOperator: filterOperator
        });
      };

      currentMatter.set('subjectColumnsForFilter', currentMatter.filterMatterFields.map(createColumn));
      /* sort matterFields by weight for property display on screen
         Remove merged fields already on the api */

      matterFields = matterFields.filter(function (mf) {
        return Ember.isEmpty(mf.get('mergeField')) || (0, _isOrphanMatterField.isOrphanMatterField)([mf, resolved.matterFields, 'isViewablePortalSubjectTable']);
      }).sortBy('weight');
      i = 0;
      var subjectColumns = [Ember.Object.create({
        displayName: 'Reviewed',
        fieldName: '',
        showIcon: 'stack-checked',
        iconType: 'sc',
        enableSearch: false,
        disableServerInteractions: true,
        apiName: '',
        order: i += 1,
        width: '75px',
        cellType: 'review',
        hoverText: 'Review'
      }), Ember.Object.create({
        displayName: 'Flag',
        fieldName: '',
        showIcon: 'flag2',
        iconType: 'sc',
        enableSearch: false,
        disableServerInteractions: true,
        apiName: '',
        order: i += 1,
        width: '75px',
        cellComponent: 'matter/subjects/cell-flag',
        hoverText: 'Flag',
        cellType: 'tool'
      }), Ember.Object.create({
        displayName: 'Published On',
        fieldName: 'publishedOn',
        enableSearch: true,
        disableServerInteractions: false,
        enabledFilterOperators: ['between_dates'],
        filterOperator: 'between_dates',
        apiName: 'published_on',
        order: i += 1,
        width: '125px'
      })];
      subjectColumns.pushObjects(matterFields.map(createColumn));
      currentMatter.set('subjectColumns', subjectColumns);
      var matterFieldFamilies = this.getMatterFieldFamilies(currentMatter.get('matterFields'));
      currentMatter.set('matterFieldFamilies', matterFieldFamilies); // we need separate fields (the one enabled for Subject Profile) when in tile view

      var profileMatterFields = currentMatter.get('matterFields').filter(function (field) {
        return field.get('value') === 'last_name' || field.get('value') === 'first_name' || field.get('isViewablePortal');
      });
      var profileMatterFieldFamilies = this.getMatterFieldFamilies(profileMatterFields);
      currentMatter.set('profileMatterFields', profileMatterFields);
      currentMatter.set('profileMatterFieldFamilies', profileMatterFieldFamilies);
      controller.set('reportTab', (0, _getScPortalReportTab.default)(resolved.scPortalReportTab));
      controller.set('totalSubjects', resolved.totalSubjects);

      this._super(controller, resolved.matter);
    },
    getMatterFieldFamilies: function getMatterFieldFamilies(matterFields) {
      var result = {};
      matterFields.forEach(function (field) {
        var value = field.get('value');
        var parent = field.get('mergeField');

        if (Ember.isPresent(parent)) {
          // we are dealing with a child field
          if (result[parent]) {
            result[parent].push(field);
          } else {
            result[parent] = [field];
          }
        } else if (!result[value]) {
          // parent or single field
          result[value] = [];
        }
      });
      return result;
    },
    actions: {
      // duplicate so this action can be reached by nested routes
      refreshInfoRoute: function refreshInfoRoute() {
        this.refresh();
      },
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});