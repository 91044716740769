define("sc-portal/services/report-builder", ["exports", "js-common/services/report-builder"], function (_exports, _reportBuilder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _reportBuilder.default;
    }
  });
});