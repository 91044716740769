define("sc-portal/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin", "js-common/mixins/currently-loading-mixin", "js-common/mixins/secure-default-route-factory", "js-common/mixins/token-route", "moment"], function (_exports, _applicationRouteMixin, _currentlyLoadingMixin, _secureDefaultRouteFactory, _tokenRoute, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  Ember.Route.reopenClass(_secureDefaultRouteFactory.default);

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _currentlyLoadingMixin.default, _tokenRoute.default, {
    session: Ember.inject.service(),
    moment: Ember.inject.service(),
    beforeModel: function beforeModel() {
      // Find the browser timezone and set it globally
      this.moment.setTimeZone(_moment.default.tz.guess());
    },
    actions: {
      invalidateSession: function invalidateSession() {
        try {
          this.session.invalidate();
        } catch (e) {
          this.transitionTo('auth.login');
        }
      }
    }
  });

  _exports.default = _default;
});