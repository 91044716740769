define("sc-portal/services/rotating-messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var getRandomInt = function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  };
  /**
   * a service to provide app with a list of messages to display to the client
   */


  var _default = Ember.Service.extend({
    // the list of messages in this format
    // { text: 'Phrase', important: false},
    messages: [],
    setMessages: function setMessages(messages) {
      this.messages = messages;
    },

    /**
     * pull messages at random unless there are some marked as important,
     * in that case, pull from this randomly
     * @returns {*}
     */
    getNewMessage: function getNewMessage() {
      var importantMessages = this.messages.filter(function (x) {
        return x.important;
      });

      if (importantMessages.length > 0) {
        return importantMessages[getRandomInt(0, importantMessages.length)].text;
      }

      return this.messages[getRandomInt(0, this.messages.length)].text;
    }
  });

  _exports.default = _default;
});