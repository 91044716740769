define("sc-portal/helpers/dates-begin-and-end-year", ["exports", "js-common/helpers/dates-begin-and-end-year"], function (_exports, _datesBeginAndEndYear) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _datesBeginAndEndYear.default;
    }
  });
  Object.defineProperty(_exports, "datesBeginAndEndYear", {
    enumerable: true,
    get: function get() {
      return _datesBeginAndEndYear.datesBeginAndEndYear;
    }
  });
});