define("sc-portal/models/matter-type", ["exports", "ember-data", "js-common/models/matter-type"], function (_exports, _emberData, _matterType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _matterType.default.extend({});

  _exports.default = _default;
});