define("sc-portal/models/hyperlink-metric", ["exports", "js-common/models/hyperlink-metric"], function (_exports, _hyperlinkMetric) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _hyperlinkMetric.default.extend({});

  _exports.default = _default;
});