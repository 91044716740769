define("sc-portal/pods/components/portal-matter-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Tm5atDnA",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[29,\"eq\",[\"matter\",[25,[\"row\",\"type\"]]],null]],null,{\"statements\":[[0,\"  \"],[5,\"sc-icon-button\",[],[[\"@icon\",\"@size\",\"@color\",\"@onClick\"],[\"folder\",\"lg\",\"secondary\",[29,\"action\",[[24,0,[]],[24,0,[\"tableActions\",\"linkAction\"]],[24,0,[\"row\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[29,\"eq\",[\"client_collection\",[25,[\"row\",\"type\"]]],null]],null,{\"statements\":[[4,\"if\",[[29,\"not-eq\",[[24,0,[\"row\",\"id\"]],\"0\"],null]],null,{\"statements\":[[0,\"    \"],[5,\"sc-icon-button\",[],[[\"@icon\",\"@size\",\"@color\",\"@onClick\"],[\"stack\",\"xl\",\"secondary\",[29,\"action\",[[24,0,[]],[24,0,[\"tableActions\",\"linkAction\"]],[24,0,[\"row\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"sc-icon-button\",[],[[\"@icon\",\"@size\",\"@color\",\"@onClick\"],[\"upload\",\"xl\",\"secondary\",[29,\"action\",[[24,0,[]],[24,0,[\"tableActions\",\"linkAction\"]],[24,0,[\"row\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sc-portal/pods/components/portal-matter-icon/template.hbs"
    }
  });

  _exports.default = _default;
});