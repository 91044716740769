define("sc-portal/pods/components/matter/report-browser/download-document/component", ["exports", "sc-portal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    metrics: Ember.inject.service(),
    fileNameFormat: null,
    downloadURI: Ember.computed('id', 'subjectId', function () {
      var url = _environment.default.APP.documentDownloadURL + this.get('id') + '/download?token=' + this.get('session.data.authenticated.token') + '&subject_id=' + this.get('subjectId');
      var fileNameFormat = this.get('fileNameFormat');

      if (!Ember.isEmpty(fileNameFormat)) {
        url += '&file_name_format=' + fileNameFormat;
      }

      return url;
    }),
    suggestedFileName: 'download.php',
    tagName: '',
    actions: {
      trackDownload: function trackDownload() {
        Ember.get(this, 'metrics').trackEvent({
          category: 'Documents',
          action: 'download',
          label: this.get('id')
        });
        return true;
      }
    }
  });

  _exports.default = _default;
});