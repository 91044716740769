define("sc-portal/helpers/auto-refresh-resume", ["exports", "js-common/helpers/auto-refresh-resume"], function (_exports, _autoRefreshResume) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _autoRefreshResume.default;
    }
  });
  Object.defineProperty(_exports, "autoRefreshResume", {
    enumerable: true,
    get: function get() {
      return _autoRefreshResume.autoRefreshResume;
    }
  });
});