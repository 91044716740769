define("sc-portal/pods/components/matter/new-report/subject-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0vhnQ0H0",
    "block": "{\"symbols\":[\"@row\"],\"statements\":[[7,\"div\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n\"],[4,\"if\",[[24,1,[\"content\",\"pending\"]]],null,{\"statements\":[[0,\"    Pending\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,1,[\"content\",\"published\"]]],null,{\"statements\":[[0,\"    Complete\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sc-portal/pods/components/matter/new-report/subject-status/template.hbs"
    }
  });

  _exports.default = _default;
});