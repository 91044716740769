define("sc-portal/components/list-sort", ["exports", "js-common/components/list-sort"], function (_exports, _listSort) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _listSort.default;
    }
  });
});