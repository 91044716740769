define("sc-portal/components/x-upload-modal", ["exports", "js-common/components/x-upload-modal"], function (_exports, _xUploadModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _xUploadModal.default;
    }
  });
});