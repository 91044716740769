define("sc-portal/pods/components/contact-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/QEPTjJt",
    "block": "{\"symbols\":[\"Modal\",\"Form\",\"&default\"],\"statements\":[[7,\"a\"],[11,\"href\",\"#\"],[9],[0,\"\\n  \"],[15,3],[0,\"\\n\"],[3,\"action\",[[24,0,[]],[29,\"mut\",[[24,0,[\"modal\"]]],null],true]],[10],[0,\"\\n\"],[4,\"if\",[[24,0,[\"modal\"]]],null,{\"statements\":[[0,\"  \"],[5,\"sc-modal\",[],[[\"@size\",\"@open\",\"@onHidden\"],[\"lg\",[24,0,[\"modal\"]],[29,\"action\",[[24,0,[]],[29,\"mut\",[[24,0,[\"modal\"]]],null],false],null]]],{\"statements\":[[0,\"\\n    \"],[6,[24,1,[\"header\"]],[],[[\"@title\"],[\"Type a message below to send to Smith & Carson Support\"]]],[0,\"\\n    \"],[5,\"sc-form\",[],[[\"@formLayout\",\"@model\",\"@onSubmit\"],[\"vertical\",[24,0,[]],[29,\"action\",[[24,0,[]],\"submit\"],null]]],{\"statements\":[[0,\"\\n      \"],[6,[24,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n        \"],[6,[24,2,[\"element\"]],[],[[\"@controlType\",\"@placeholder\",\"@property\",\"@required\"],[\"text\",\"Subject\",\"subject\",true]]],[0,\"\\n        \"],[6,[24,2,[\"element\"]],[],[[\"@controlType\",\"@placeholder\",\"@property\",\"@required\"],[\"textarea\",\"Message\",\"message\",true]]],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n      \"],[6,[24,1,[\"footer\"]],[],[[],[]],{\"statements\":[[0,\"\\n        \"],[5,\"sc-button\",[],[[\"@onClick\",\"@type\",\"@color\"],[[29,\"action\",[[24,0,[]],[24,1,[\"close\"]]],null],\"contents\",\"steel\"]],{\"statements\":[[0,\"\\n          Cancel\\n        \"]],\"parameters\":[]}],[0,\"\\n        \"],[5,\"sc-button\",[],[[\"@color\",\"@buttonType\",\"@onClick\"],[\"primary\",\"submit\",[29,\"action\",[[24,0,[]],\"submit\"],null]]],{\"statements\":[[0,\"\\n          \"],[1,[29,\"fa-icon\",[\"send\"],null],false],[0,\"\\n          Send\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[2]}],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sc-portal/pods/components/contact-form/template.hbs"
    }
  });

  _exports.default = _default;
});