define("sc-portal/pods/components/modals/case-group-workflow/component", ["exports", "@smith-carson/ui/pods/components/modals/case-group-workflow/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _component.default;
    }
  });
});