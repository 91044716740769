define("sc-portal/pods/components/matter/subjects/cell-flag/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mjKpn3dg",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"row\",\"showIsFlagged\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"id\",[29,\"concat\",[\"flag-\",[25,[\"row\",\"id\"]]],null]],[11,\"class\",\"review-icon text-center\"],[9],[0,\"\\n    \"],[5,\"sc-icon\",[],[[\"@icon\",\"@color\"],[\"flag2\",\"jade\"]]],[0,\"\\n  \"],[10],[0,\"\\n  \"],[5,\"sc-popover\",[],[[\"@title\",\"@triggerElement\",\"@triggerEvents\"],[\"Flag\",[29,\"concat\",[\"#flag-\",[25,[\"row\",\"id\"]]],null],\"hover\"]],{\"statements\":[[0,\"\\n    \"],[1,[29,\"nl-2br\",[[25,[\"row\",\"flagNote\"]]],null],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sc-portal/pods/components/matter/subjects/cell-flag/template.hbs"
    }
  });

  _exports.default = _default;
});