define("sc-portal/pods/components/sc-external-layout/right-panel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eFPAzPrb",
    "block": "{\"symbols\":[\"Col\",\"&default\",\"@col\"],\"statements\":[[4,\"let\",[[24,3,[]]],null,{\"statements\":[[0,\"  \"],[6,[24,1,[]],[[13,\"class\",\"sc-external-layout__right-panel\"]],[[],[]],{\"statements\":[[0,\"\\n    \"],[15,2],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sc-portal/pods/components/sc-external-layout/right-panel/template.hbs"
    }
  });

  _exports.default = _default;
});