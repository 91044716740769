define("sc-portal/pods/components/sc-light-table/cells/subject/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HCgMu8zX",
    "block": "{\"symbols\":[\"@column\",\"@row\",\"@extra\",\"@rawValue\",\"@value\",\"@table\",\"@tableActions\"],\"statements\":[[7,\"div\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"column\",\"cellComponent\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"component\",[[25,[\"column\",\"cellComponent\"]]],[[\"tableActions\",\"table\",\"column\",\"row\",\"value\",\"rawValue\",\"appName\"],[[24,7,[]],[24,6,[]],[24,1,[]],[24,2,[]],[24,5,[]],[24,4,[]],\"js-client\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"subject-field-read\",[[13,\"class\",\"trimmed-content\"]],[[\"@currentMatterFieldValue\",\"@subject\",\"@data\",\"@matterFieldFamilies\",\"@sortedMatterFields\",\"@isTable\"],[[24,1,[\"scColumn\",\"apiInteractionName\"]],[24,2,[\"content\"]],[24,0,[\"analyticsObject\",\"analyticsData\"]],[24,3,[\"matterFieldFamilies\"]],[24,3,[\"matterFields\"]],true]]],[0,\"\\n\"]],\"parameters\":[]}],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sc-portal/pods/components/sc-light-table/cells/subject/template.hbs"
    }
  });

  _exports.default = _default;
});