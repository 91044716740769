define("sc-portal/components/as-scrollable", ["exports", "ember-scrollable/components/ember-scrollable"], function (_exports, _emberScrollable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberScrollable.default.extend({
    classNames: 'as-scrollable'
  });

  _exports.default = _default;
});