define("sc-portal/components/analytics-modules/education-analytics", ["exports", "js-common/components/analytics-modules/education-analytics"], function (_exports, _educationAnalytics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _educationAnalytics.default;
    }
  });
});