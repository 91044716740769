define("sc-portal/pods/auth/login/controller", ["exports", "sc-portal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    notify: Ember.inject.service(),
    // Rotate different messages on the login page
    rotatingMessages: Ember.inject.service(),
    currentMessage: '',
    isAuthenticating: false,
    successful: false,
    token: false,
    initiateController: Ember.on('init', function () {
      this.rotatingMessages.setMessages(_environment.default.welcomeMessages);
      this.set('currentMessage', this.rotatingMessages.getNewMessage());
    }),
    year: Ember.computed(function () {
      return new Date().getFullYear();
    }).volatile(),
    actions: {
      /**
       * handle login form authenticate request
       * TODO validate inputs
       */
      authenticate: function authenticate() {
        var _this = this;

        if (this.isAuthenticating) {
          return;
        }

        this.set('isAuthenticating', true);
        var identification = this.identification,
            password = this.password,
            otpToken = this.otpToken,
            rememberDevice = this.rememberDevice;
        this.session.authenticate('authenticator:custom', identification, window.btoa(password), otpToken, rememberDevice).then(function () {
          _this.set('successful', true);

          var previousTransition = _this.previousTransition;

          if (previousTransition) {
            _this.set('previousTransition', null);

            previousTransition.retry();
          }

          var previousUrl = _this.model.previous_url;

          if (previousUrl) {
            document.location.assign(previousUrl);
          }
        }).catch(function (reason) {
          if (reason.verify_token) {
            _this.get('notify').warning('Please enter your verification code send to via ' + reason.verify_token);

            _this.set('transport', reason.verify_token.toUpperCase());

            _this.set('token', true);

            return;
          }

          _this.set('errorMessage', reason && 'error' in reason ? reason.error : reason);
        }).finally(function () {
          _this.set('isAuthenticating', false);
        });
      }
    }
  });

  _exports.default = _default;
});