define("sc-portal/pods/change-password/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    model: function model() {
      var clientId = this.get('session.data.authenticated.id');
      return this.store.findRecord('user', clientId);
    }
  });

  _exports.default = _default;
});