define("sc-portal/pods/components/sc-light-table/cells/new-report-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6/Ge65zA",
    "block": "{\"symbols\":[\"&default\",\"@value\"],\"statements\":[[15,1],[0,\"\\n\"],[1,[24,2,[]],false],[0,\"\\n\"],[7,\"strong\"],[9],[0,\"\\n  PH:\\n\"],[10],[0,\"\\nComplete - 2/14/18 @ 4:20pm\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sc-portal/pods/components/sc-light-table/cells/new-report-status/template.hbs"
    }
  });

  _exports.default = _default;
});