define("sc-portal/components/sc-light-table/cells/tool", ["exports", "@smith-carson/ui/components/sc-light-table/cells/tool"], function (_exports, _tool) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _tool.default;
    }
  });
});