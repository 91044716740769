define("sc-portal/models/resource-has-tag", ["exports", "js-common/models/resource-has-tag"], function (_exports, _resourceHasTag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resourceHasTag.default.extend({});

  _exports.default = _default;
});