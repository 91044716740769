define("sc-portal/pods/components/sc-external-layout/reminder/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['sc-external-layout__reminder'],
    model: {
      email: null
    }
  });

  _exports.default = _default;
});