define("sc-portal/models/analytic-list", ["exports", "js-common/models/analytic-list"], function (_exports, _analyticList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _analyticList.default.extend({});

  _exports.default = _default;
});