define("sc-portal/pods/error/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m3s4r5eq",
    "block": "{\"symbols\":[],\"statements\":[[5,\"sc-container\",[[13,\"class\",\"mt-5 text-center\"]],[[\"@width\"],[\"fluid\"]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[29,\"compute\",[[29,\"action\",[[24,0,[]],\"check401\"],null],[25,[\"model\",\"message\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"h3\"],[9],[0,\"\\n      Redirecting unauthenticated user to login page...\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"h1\"],[11,\"class\",\"mb-3\"],[9],[0,\"\\n      Error\\n    \"],[10],[0,\"\\n    \"],[7,\"h3\"],[9],[0,\"\\n      Please report this error to the administrator\\n    \"],[10],[0,\"\\n    \"],[7,\"p\"],[9],[0,\"\\n      We will work on fixing that right away.\\n    \"],[10],[0,\"\\n    \"],[7,\"p\"],[9],[0,\"\\n      It's possible that some data you requested is not accessible by your\\n      profile.\\n    \"],[10],[0,\"\\n    \"],[7,\"p\"],[9],[0,\"\\n      Please click your browser\\n      \"],[7,\"a\"],[11,\"href\",\"javascript: history.back();\"],[9],[0,\"\\n        Back\\n      \"],[10],[0,\"\\n      button to return to the previous page.\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sc-portal/pods/error/template.hbs"
    }
  });

  _exports.default = _default;
});