define("sc-portal/helpers/human-file-size", ["exports", "@smith-carson/ui/helpers/human-file-size"], function (_exports, _humanFileSize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _humanFileSize.default;
    }
  });
  Object.defineProperty(_exports, "humanFileSize", {
    enumerable: true,
    get: function get() {
      return _humanFileSize.humanFileSize;
    }
  });
});