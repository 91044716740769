define("sc-portal/pods/auth/otp-request/route", ["exports", "js-common/mixins/crud/error", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _error, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_error.default, _unauthenticatedRouteMixin.default, {
    notify: Ember.inject.service(),
    ajax: Ember.inject.service(),
    // reset the model in case you return to add another record
    model: function model() {
      return {
        email: null,
        app: 'sc-portal',
        verified: null
      };
    },
    actions: {
      goToLogin: function goToLogin() {
        this.transitionTo('auth.login');
      },
      requestOtp: function requestOtp(model) {
        var _this = this;

        var controller = this.controllerFor(this.get('routeName'));

        if (this.get('isProcessing')) {
          return;
        }

        this.set('isProcessing', true);

        if (Ember.isEmpty(controller.get('reCaptchaResponse'))) {
          this.get('notify').error('Please verify that you are not a robot');
          this.set('isProcessing', false);
        } else {
          model.verified = controller.get('reCaptchaResponse');
          this.ajax.post('auth/otp/generate', {
            data: model
          }).then(function (results) {
            _this.get('notify').success('Check your email!');

            _this.set('isProcessing', false);
          }).catch(function (response) {
            if (response.payload) {
              if (response.payload.errors) {
                response.payload.errors.forEach(function (error) {
                  _this.get('notify').error(error.additional_info.title);
                });
              }
            } else {
              _this.get('notify').error(response);
            }

            _this.set('isProcessing', false);
          });
        }
      }
    }
  });

  _exports.default = _default;
});