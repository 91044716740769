define("sc-portal/utils/sort-timeline-events-by-date-and-accuracy", ["exports", "js-common/utils/sort-timeline-events-by-date-and-accuracy"], function (_exports, _sortTimelineEventsByDateAndAccuracy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _sortTimelineEventsByDateAndAccuracy.default;
    }
  });
});