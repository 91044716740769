define("sc-portal/pods/403/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QG3WIoQx",
    "block": "{\"symbols\":[],\"statements\":[[5,\"sc-container\",[[13,\"class\",\"mt-5 text-center\"]],[[\"@width\"],[\"fluid\"]],{\"statements\":[[0,\"\\n  \"],[7,\"h1\"],[11,\"class\",\"mb-3\"],[9],[0,\"\\n    403 - Not Authorized\\n  \"],[10],[0,\"\\n  \"],[7,\"h3\"],[9],[0,\"\\n    You are not authorized to view this page.\\n  \"],[10],[0,\"\\n  \"],[7,\"p\"],[9],[0,\"\\n    Your account permissions do not permit you to view this page.\\n  \"],[10],[0,\"\\n  \"],[7,\"p\"],[9],[0,\"\\n    Please click your browser\\n    \"],[7,\"a\"],[11,\"href\",\"javascript: history.back();\"],[9],[0,\"\\n      back\\n    \"],[10],[0,\"\\n    button to return to the previous page.\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sc-portal/pods/403/template.hbs"
    }
  });

  _exports.default = _default;
});