define("sc-portal/models/v-note-parent", ["exports", "js-common/models/v-note-parent"], function (_exports, _vNoteParent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _vNoteParent.default.extend({});

  _exports.default = _default;
});