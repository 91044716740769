define("sc-portal/pods/error/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      check401: function check401(message) {
        return message.indexOf('401') >= 0;
      }
    }
  });

  _exports.default = _default;
});