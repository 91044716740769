define("sc-portal/models/user-reviewed-resource", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    createdOn: _emberData.default.attr('utcdate'),
    updatedOn: _emberData.default.attr('utcdate'),
    // relationships
    resource: _emberData.default.belongsTo('resource', {
      async: true
    }),
    user: _emberData.default.belongsTo('user', {
      async: false
    })
  });

  _exports.default = _default;
});