define("sc-portal/utils/validate-color", ["exports", "@smith-carson/ui/utils/validate-color"], function (_exports, _validateColor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _validateColor.default;
    }
  });
});