define("sc-portal/models/v-resource-task", ["exports", "js-common/models/v-resource-task"], function (_exports, _vResourceTask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _vResourceTask.default.extend({});

  _exports.default = _default;
});