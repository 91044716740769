define("sc-portal/pods/components/sc-light-table/cells/portal-matter-match/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NWS1Rvo7",
    "block": "{\"symbols\":[\"row\"],\"statements\":[[1,[23,\"value\"],false],[0,\"\\n\"],[4,\"with\",[[24,0,[\"row\",\"content\"]]],null,{\"statements\":[[4,\"if\",[[24,1,[\"matchedBy\"]]],null,{\"statements\":[[0,\"    \"],[7,\"br\"],[9],[10],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"small\"],[9],[0,\"\\n      \"],[1,[24,1,[\"matchedBy\"]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sc-portal/pods/components/sc-light-table/cells/portal-matter-match/template.hbs"
    }
  });

  _exports.default = _default;
});