define("sc-portal/components/subject-icons/reviewed", ["exports", "js-common/components/subject-icons/reviewed"], function (_exports, _reviewed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _reviewed.default;
    }
  });
});