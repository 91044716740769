define("sc-portal/helpers/state-name-to-abbr", ["exports", "@smith-carson/ui/helpers/state-name-to-abbr"], function (_exports, _stateNameToAbbr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _stateNameToAbbr.default;
    }
  });
  Object.defineProperty(_exports, "stateNameToAbbr", {
    enumerable: true,
    get: function get() {
      return _stateNameToAbbr.stateNameToAbbr;
    }
  });
});