define("sc-portal/pods/matter/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    jurorLocations: Ember.computed(function () {
      var _this = this;

      return this.model.getJurorLocationsCount().then(function (response) {
        _this.set('jurorLocations', response.juror_locations ? response.juror_locations.map(function (jl) {
          return Ember.Object.create({
            name: jl.location_name ? Ember.String.capitalize(jl.location_name) : 'Location not set',
            value: jl.location_name,
            count: jl.count
          });
        }) : []);
      });
    })
  });

  _exports.default = _default;
});