define("sc-portal/helpers/is-orphan-matter-field", ["exports", "js-common/helpers/is-orphan-matter-field"], function (_exports, _isOrphanMatterField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _isOrphanMatterField.default;
    }
  });
  Object.defineProperty(_exports, "arrayByIndex", {
    enumerable: true,
    get: function get() {
      return _isOrphanMatterField.arrayByIndex;
    }
  });
});