define("sc-portal/helpers/permissions-check-any", ["exports", "js-common/helpers/permissions-check-any"], function (_exports, _permissionsCheckAny) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _permissionsCheckAny.default;
    }
  });
  Object.defineProperty(_exports, "permissionsCheckAny", {
    enumerable: true,
    get: function get() {
      return _permissionsCheckAny.permissionsCheckAny;
    }
  });
});