define("sc-portal/services/sc-channels", ["exports", "js-common/services/sc-channels"], function (_exports, _scChannels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _scChannels.default;
    }
  });
});