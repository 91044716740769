define("sc-portal/components/sc-table/no-data", ["exports", "@smith-carson/ui/components/sc-table/no-data"], function (_exports, _noData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _noData.default;
    }
  });
});