define("sc-portal/adapters/analytic-list", ["exports", "sc-portal/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType: function pathForType(modelName) {
      return 'subject_analytics/get_analytics_list';
    }
  });

  _exports.default = _default;
});