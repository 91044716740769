define("sc-portal/pods/portal-matter/model", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _emberData.default.Model.extend({
    type: attr('string'),
    // matter or client_collection
    name: attr('string'),
    personOfInterest: attr('string'),
    nickName: attr('string'),
    plaintiff: attr('string'),
    defendant: attr('string'),
    startDate: attr('estdate'),
    venueCounty: attr('string'),
    tags: attr('string'),
    hideDashboard: attr('number'),
    hideSubjects: attr('number'),
    hideDocuments: attr('number'),
    createdOn: attr('estdate'),
    matchedBy: attr('string'),
    createdOnFormatted: Ember.computed('createdOn', function () {
      return (0, _moment.default)(this.get('createdOn')).format('MM/DD/YYYY');
    })
  });

  _exports.default = _default;
});