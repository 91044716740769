define("sc-portal/helpers/bind", ["exports", "ember-bind-helper/helpers/bind"], function (_exports, _bind) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _bind.default;
    }
  });
  Object.defineProperty(_exports, "bind", {
    enumerable: true,
    get: function get() {
      return _bind.bind;
    }
  });
});