define("sc-portal/models/hyperlink", ["exports", "js-common/models/hyperlink"], function (_exports, _hyperlink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _hyperlink.default.extend({});

  _exports.default = _default;
});