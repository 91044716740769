define("sc-portal/instance-initializers/notifyfix", ["exports", "@babel/runtime/helpers/esm/typeof", "jquery", "sc-portal/config/environment"], function (_exports, _typeof2, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  // The scope of this is to allow logging capabilities for the notify service in the testing ENV
  // We can use assertions for the notifications text
  function aliasToShowAndLog(type) {
    return function (message, options) {
      var container = (0, _jquery.default)('div.ember-notify-testing');

      if (container.length === 0) {
        container = (0, _jquery.default)('<div class="ember-notify-testing" style="display: none;"></div>').appendTo(_environment.default.APP.rootElement || (0, _jquery.default)('.ember-application')[0]);
      }

      var html = '';
      var text = '';

      if ((0, _typeof2.default)(message) === 'object') {
        html = message.html || '';
        text = message.text || '';
      } else {
        text = message;
      }

      container.append("<span data-test-message='".concat(type, "'>").concat(text).concat(html));
      return this.show(type, message, options);
    };
  }

  function initialize(instance) {
    if (_environment.default.enviroment !== 'production') {
      var service = instance.lookup('service:notify');
      service.info = aliasToShowAndLog('info');
      service.success = aliasToShowAndLog('success');
      service.warning = aliasToShowAndLog('warning');
      service.alert = aliasToShowAndLog('alert');
      service.error = aliasToShowAndLog('error');
    }
  }

  var _default = {
    name: 'notifyfix',
    initialize: initialize
  };
  _exports.default = _default;
});