define("sc-portal/models/passive-event", ["exports", "js-common/models/passive-event"], function (_exports, _passiveEvent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _passiveEvent.default.extend({});

  _exports.default = _default;
});