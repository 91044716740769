define("sc-portal/pods/auth/reminder/route", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    // reset the model in case you return to add another record
    model: function model() {
      return {
        email: null,
        appName: 'sc-portal'
      };
    },
    actions: {
      goToLogin: function goToLogin() {
        this.transitionTo('auth.login');
      },
      goToOtpRequest: function goToOtpRequest() {
        this.transitionTo('auth.otp-request');
      }
    }
  });

  _exports.default = _default;
});