define("sc-portal/models/client-invoice-history", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * this model relies on a faux api model
   * there is no dedicated end point backing this model, it is only populated through side loading
   */
  var _default = _emberData.default.Model.extend({
    mostRecentInvoice: _emberData.default.attr('number'),
    mostRecentInvoiceBillDate: _emberData.default.attr('utcdate'),
    yearToDateRevenue: _emberData.default.attr('number'),
    allRevenue: _emberData.default.attr('number'),
    allInvoiceCount: _emberData.default.attr('number'),
    yearToDateInvoiceCount: _emberData.default.attr('number'),
    // relationships
    client: _emberData.default.belongsTo('client', {
      async: false
    })
  });

  _exports.default = _default;
});