define("sc-portal/components/one-way-email", ["exports", "ember-one-way-controls/components/one-way-email"], function (_exports, _oneWayEmail) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _oneWayEmail.default;
    }
  });
});