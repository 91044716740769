define("sc-portal/pods/auth/otp-request/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qG53aR7v",
    "block": "{\"symbols\":[\"Layout\"],\"statements\":[[5,\"sc-external-layout\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[6,[24,1,[\"leftPanel\"]],[],[[],[]]],[0,\"\\n  \"],[6,[24,1,[\"rightPanel\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"sc-otp-request\",[],[[\"@year\",\"@isProcessing\",\"@model\",\"@goToLogin\",\"@requestOtp\",\"@reCaptchaResponse\"],[[24,0,[\"year\"]],[24,0,[\"isProcessing\"]],[24,0,[\"model\"]],[29,\"route-action\",[\"goToLogin\"],null],[29,\"route-action\",[\"requestOtp\"],null],[24,0,[\"reCaptchaResponse\"]]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sc-portal/pods/auth/otp-request/template.hbs"
    }
  });

  _exports.default = _default;
});