define("sc-portal/pods/components/forms/document/create-update/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g2DFtID7",
    "block": "{\"symbols\":[\"Uploader\",\"@formOptions\",\"&default\"],\"statements\":[[5,\"sc-file-uploader\",[],[[\"@files\",\"@uploadURL\",\"@buildExtraParams\",\"@showUploadNotifications\",\"@onFileChange\"],[[29,\"array\",[[24,0,[\"file\"]]],null],[24,0,[\"uploadUrl\"]],[29,\"optional\",[[24,0,[\"buildExtraParams\"]]],null],false,[29,\"action\",[[24,0,[]],[24,0,[\"setFile\"]]],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[26,3]],null,{\"statements\":[[0,\"    \"],[15,3,[[29,\"hash\",null,[[\"id\",\"form\",\"progressBar\",\"controls\",\"fileUploader\",\"save\",\"cancel\",\"remove\"],[[24,0,[\"id\"]],[29,\"component\",[\"forms/document/create-update/form\"],[[\"model\",\"options\",\"remove\",\"isSaving\",\"uploader\",\"file\"],[[24,0,[\"_model\"]],[24,2,[]],[29,\"action\",[[24,0,[]],[24,0,[\"remove\"]]],null],[24,0,[\"_saveTask\",\"isRunning\"]],[24,1,[]],[24,0,[\"file\"]]]]],[29,\"component\",[\"forms/document/create-update/progress-bar\"],[[\"progress\"],[[24,1,[\"fileUploader\",\"progress\"]]]]],[29,\"component\",[\"forms/document/create-update/controls\"],[[\"isSaving\",\"save\",\"cancel\"],[[24,0,[\"_saveTask\",\"isRunning\"]],[29,\"action\",[[24,0,[]],[24,0,[\"save\"]],[24,1,[\"fileUploader\",\"uploadTask\"]]],null],[29,\"action\",[[24,0,[]],[24,0,[\"cancel\"]]],null]]]],[24,1,[\"fileUploader\"]],[29,\"action\",[[24,0,[]],[24,0,[\"save\"]],[24,1,[\"fileUploader\",\"upload\"]]],null],[24,0,[\"cancel\"]],[24,0,[\"remove\"]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[],\"parameters\":[]}]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sc-portal/pods/components/forms/document/create-update/template.hbs"
    }
  });

  _exports.default = _default;
});