define("sc-portal/helpers/slash-2br", ["exports", "js-common/helpers/slash-2br"], function (_exports, _slash2br) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _slash2br.default;
    }
  });
  Object.defineProperty(_exports, "nl2br", {
    enumerable: true,
    get: function get() {
      return _slash2br.nl2br;
    }
  });
});