define("sc-portal/adapters/filtered-batch", ["exports", "sc-portal/adapters/application", "sc-portal/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForFindAll: function urlForFindAll(modelName, snapshot) {
      return _environment.default.APP.restDestination + '/' + _environment.default.APP.restNameSpace + '/invoice_batches/filtered_by_user';
    }
  });

  _exports.default = _default;
});