define("sc-portal/pods/components/sc-light-table/cells/document-delete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KIZeNlCK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n  \"],[5,\"sc-icon-button\",[],[[\"@icon\",\"@size\",\"@color\",\"@onClick\"],[\"trash\",\"lg\",\"secondary\",[29,\"action\",[[24,0,[]],[24,0,[\"tableActions\",\"deleteDoc\"]],[24,0,[\"row\",\"content\"]]],null]]]],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sc-portal/pods/components/sc-light-table/cells/document-delete/template.hbs"
    }
  });

  _exports.default = _default;
});