define("sc-portal/components/document-preview-url", ["exports", "js-common/components/document-preview-url"], function (_exports, _documentPreviewUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _documentPreviewUrl.default;
    }
  });
});