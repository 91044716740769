define("sc-portal/pods/components/sc-light-table/cells/document-download/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5jnhji/3",
    "block": "{\"symbols\":[\"@value\"],\"statements\":[[7,\"a\"],[11,\"role\",\"button\"],[9],[0,\"\\n  \"],[1,[24,1,[]],false],[0,\"\\n\"],[3,\"action\",[[24,0,[]],[24,0,[\"tableActions\",\"downloadDoc\"]],[24,0,[\"row\"]]]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sc-portal/pods/components/sc-light-table/cells/document-download/template.hbs"
    }
  });

  _exports.default = _default;
});