define("sc-portal/models/key-attribute-rule", ["exports", "js-common/models/key-attribute-rule"], function (_exports, _keyAttributeRule) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _keyAttributeRule.default.extend({});

  _exports.default = _default;
});