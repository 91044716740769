define("sc-portal/services/ajax", ["exports", "ember-ajax/services/ajax", "sc-portal/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    host: _environment.default.APP.restDestination,
    namespace: _environment.default.APP.restNameSpace,
    session: Ember.inject.service(),
    headers: Ember.computed('session.data.authenticated.token', {
      get: function get() {
        var headers = {};
        var token = this.get('session.data.authenticated.token');

        if (token) {
          headers['X-Authorization'] = 'Token: ' + token;
        }

        if (_environment.default.environment === 'test') {
          headers['X-CI-KEY'] = _environment.default.cikey;
        }

        return headers;
      }
    })
  });

  _exports.default = _default;
});