define("sc-portal/helpers/transition-to-external", ["exports", "ember-route-helpers/helpers/transition-to-external"], function (_exports, _transitionToExternal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _transitionToExternal.default;
    }
  });
  Object.defineProperty(_exports, "transitionToExternal", {
    enumerable: true,
    get: function get() {
      return _transitionToExternal.transitionToExternal;
    }
  });
});