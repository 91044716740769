define("sc-portal/services/report-catalog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // /////////////////////////////////////////////////////////////////////////////////////////////////////////
  //  COMPLETE LIST OF OPTIONS FOR REPORT OBJECT                                                            //
  //  - apiName      - The name the report on the api                                                       //
  //  - displayName  - The name that will be shown on the table header                                      //
  //  - perPage      - The pagination limit default                                                         //
  //  - route        - The Ember route that the report is located at                                        //
  //  - description  - A description of the report                                                          //
  //  - component    - An optional custom component for the table layout for the report                     //
  //  - subReport    - A boolean for whether report stands on its own (will not be on list page if true)    //
  //  - parentReport - Optional - designates parent report of a sub report                                  //
  // /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // report object
  var Report = Ember.Object.extend({
    apiName: 'CHANGE ME',
    perPage: 100,
    component: 'report/default-table',
    displayName: 'CHANGE ME',
    description: null,
    category: 'General'
  });
  /**
   * a service to store all client side knowledge of our api reports
   */

  var _default = Ember.Service.extend({
    catalog: [],

    /**
     * populate the catalog with all known reports
     */
    onInit: Ember.on('init', function () {
      this._super.apply(this, arguments);

      this.catalog.push(Report.create({
        apiName: 'fake_report',
        displayName: 'Fake Report',
        perPage: 500,
        route: 'report.info.fake-report'
      }));
      this.catalog.push(Report.create({
        apiName: 'client_file_uploads',
        displayName: 'Client File Report',
        description: 'List recently uploaded files by Client Portal users',
        component: 'report/client-upload-table',
        route: 'report.info.client-file-report'
      }));
      this.catalog.push(Report.create({
        apiName: 'utilization_report',
        displayName: 'Utilization Report',
        perPage: 500,
        description: 'Show Billing Information',
        route: 'report.info.utilization-report'
      }));
      this.catalog.push(Report.create({
        apiName: 'review-time-report',
        displayName: 'Review Time Report',
        perPage: 500,
        description: 'Show time related to a person or case',
        route: 'report.info.review-time-report',
        subReport: true,
        parentReport: 'utilization_report'
      }));
      this.catalog.push(Report.create({
        apiName: 'sales-report',
        displayName: 'Sales Report',
        perPage: 500,
        description: 'Gathers together groups of cases and calculates summary style invoice data for each group',
        component: 'report/sales-report',
        route: 'report.info.sales-report'
      }));
      this.catalog.push(Report.create({
        apiName: 'billed_cases_by_month',
        displayName: 'Billed Cases By Month',
        perPage: 500,
        description: 'Allows the user to have an overview of the case work that took place for any given month.',
        component: 'report/billed-cases-by-month',
        route: 'report.info.billed-cases-by-month'
      }));
      this.catalog.push(Report.create({
        apiName: 'billed_cases_detail',
        displayName: 'Billed Cases Detail',
        perPage: 500,
        description: 'Allows a user to see the billed items for a given month and case type',
        component: 'report/billed-cases-detail',
        route: 'report.info.billed-cases-detail',
        subReport: true
      }));
      this.catalog.push(Report.create({
        apiName: 'new_cases_report',
        displayName: 'New Cases',
        perPage: 500,
        description: 'Show the users a list of new cases through various filters',
        component: 'report/new-cases-table',
        route: 'report.info.new-cases'
      }));
      this.catalog.push(Report.create({
        apiName: 'new_cases_detail_report',
        displayName: 'New Cases Detail',
        perPage: 500,
        description: 'Show a list of the cases that were created in a particular month',
        component: 'report/new-cases-detail',
        route: 'report.info.new-cases-detail',
        subReport: true
      }));
      this.catalog.push(Report.create({
        apiName: 'monthly_time_summary',
        displayName: 'Monthly Time Summary',
        perPage: 500,
        description: 'allows the user to view the hours and generated revenue that employees accrued during a given date range.',
        component: 'report/monthly-time-summary',
        route: 'report.info.monthly-time-summary'
      }));
    }),

    /**
     * return a particular report based on it's apiName
     *
     * @param apiName
     * @returns {*|Object}
     */
    getReport: function getReport(apiName) {
      return this.catalog.findBy('apiName', apiName);
    },

    /**
     * return a list of reports, but just a sub set of the data
     * suitable for display
     * todo expand to group reports by category
     * @returns {Array}
     */
    getReportList: function getReportList() {
      return this.catalog;
    }
  });

  _exports.default = _default;
});