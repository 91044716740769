define("sc-portal/pods/components/matter/subjects/subject-flags/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tnxzP71Q",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,0,[\"row\",\"content\",\"showIsResearchedBadge\"]]],null,{\"statements\":[[0,\"  \"],[5,\"sc-badge\",[[13,\"title\",\"Case Subject Researched\"]],[[\"@color\"],[\"info\"]],{\"statements\":[[0,\"\\n    \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"check\"]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sc-portal/pods/components/matter/subjects/subject-flags/template.hbs"
    }
  });

  _exports.default = _default;
});