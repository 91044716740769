define("sc-portal/serializers/v-matter-user-permission", ["exports", "active-model-adapter"], function (_exports, _activeModelAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var id = 1;
  /**
   * provide an expected subject_id that the view/api would not otherwise provide
   */

  var _default = _activeModelAdapter.ActiveModelSerializer.extend({
    normalize: function normalize(typeClass, hash, prop) {
      hash.id = id++;
      return this._super(typeClass, hash, prop);
    },
    // http://emberjs.com/blog/2015/06/18/ember-data-1-13-released.html#toc_upgrade-guide
    isNewSerializerAPI: true
  });

  _exports.default = _default;
});